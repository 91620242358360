<template>
  <v-btn
    :minWidth="$vuetify.breakpoint.smAndDown ? '152' : '170'"
    :ripple="false"
    :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
    depressed
    :small="$vuetify.breakpoint.smAndDown"
    class="black-btn px-md-9 px-11">
    <span
			class="vue-editor font-weight-bold white--text"
			v-html="trans('welcome_page_personal')"
    />
  </v-btn>
</template>

<script>
export default {
name: 'PersonalBtn'
}
</script>

<style lang="scss" scoped>
.black-btn {
  border-radius: 30px !important;
  background: var(--v-black-base) !important;
  color: white !important;
  transition: all 0.4s;

  &:hover {
    background: rgba($color: #000000, $alpha: 0.9) !important;
  }
}

</style>

<template>
	<v-btn
		:minWidth="$vuetify.breakpoint.smAndDown ? '152' : '170'"
		:ripple="false"
		:height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
		depressed
		:small="$vuetify.breakpoint.smAndDown"
		class="business-btn"
		@click="$emit('click')"
	>
		<span
			class="vue-editor font-weight-bold"
			v-html="trans('welcome_page_business')"
		/>
	</v-btn>
</template>

<script>
export default {
	name: "Business",
};
</script>

<style lang="scss" scoped>
.business-btn {
	border: 1px var(--v-mainColor-base) solid;
	border-radius: 30px !important;
	background: transparent !important;
	transition: all 0.4s;
	color: var(--v-mainColor-base);
	&:hover {
		background: var(--v-mainColor-base) !important;
		color: white;
	}
}
</style>

import PreviewLogo from "@/components/welcome/PreviewLogo";
import PersonalBtn from "@/components/btns/PersonalBtn";
import BusinessBtn from "@/components/btns/BusinessBtn";

export default {
	name: "Subheader",
	components: {
		PreviewLogo,
		PersonalBtn,
		BusinessBtn,
	},
	methods: {
		goToPortal() {
			window.location.href = "https://app.missionone.live";
		},
	},
};
